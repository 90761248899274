import {
    ScmIntegrationsApi,
    scmIntegrationsApiRef,
    ScmAuth,
    // scmAuthApiRef
} from '@backstage/integration-react';

import {
    AnyApiFactory,
    ApiRef,
    BackstageIdentityApi,
    OAuthApi,
    OpenIdConnectApi,
    ProfileInfoApi,
    SessionApi,
    configApiRef,
    createApiFactory,
    createApiRef,
    discoveryApiRef,
    oauthRequestApiRef,
} from '@backstage/core-plugin-api';
import { OAuth2 } from '@backstage/core-app-api';

export const ppAuthApiRef: ApiRef<
    OAuthApi &
    OpenIdConnectApi &
    ProfileInfoApi &
    BackstageIdentityApi &
    SessionApi
> = createApiRef({
    id: 'internal.auth.pp',
});

export const apis: AnyApiFactory[] = [
    createApiFactory({
        api: scmIntegrationsApiRef,
        deps: { configApi: configApiRef },
        factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
    }),
    ScmAuth.createDefaultApiFactory(),
    createApiFactory({
        api: ppAuthApiRef,
        deps: {
            discoveryApi: discoveryApiRef,
            oauthRequestApi: oauthRequestApiRef,
            configApi: configApiRef,
        },
        factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
            OAuth2.create({
                discoveryApi,
                oauthRequestApi,
                configApi,
                defaultScopes: ['openid', 'email', 'profile', 'offline_access', 'roles'],
                environment: configApi.getOptionalString('auth.environment'),
            }),
    }),
];

