import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import { SearchPage } from '@backstage/plugin-search';
import {
    CatalogEntityPage,
    CatalogIndexPage,
    catalogPlugin,
} from '@backstage/plugin-catalog';
import {
    CatalogImportPage,
    catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import {
    TechDocsIndexPage,
    techdocsPlugin,
    TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis, ppAuthApiRef } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog, SignInPage, SignInProviderConfig } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';

import { VersionDriftPage } from '@internal/plugin-version-drift';
import { searchPage } from './components/search/SearchPage';

const oauthProvider: SignInProviderConfig = {
    id: 'oauth2',
    title: 'Sign In',
    message: 'Sign in using Keycloak',
    apiRef: ppAuthApiRef,
};

const app = createApp({
    components: {
        SignInPage: props => (
            <SignInPage {...props} auto={false} provider={oauthProvider} />
        ),
    },
    apis,
    bindRoutes({ bind }) {
        bind(catalogPlugin.externalRoutes, {
            createComponent: scaffolderPlugin.routes.root,
            viewTechDoc: techdocsPlugin.routes.docRoot,
            createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
        });
        bind(apiDocsPlugin.externalRoutes, {
            registerApi: catalogImportPlugin.routes.importPage,
        });
        bind(scaffolderPlugin.externalRoutes, {
            registerComponent: catalogImportPlugin.routes.importPage,
            viewTechDoc: techdocsPlugin.routes.docRoot,
        });
        bind(orgPlugin.externalRoutes, {
            catalogIndex: catalogPlugin.routes.catalogIndex,
        });
    },
});

const AppRoot = (
    <>
        <AlertDisplay />
        <OAuthRequestDialog />
        <AppRouter>
            <Root>
                <FlatRoutes>
                    <Route path="/" element={<Navigate to={'catalog'} />} />
                    <Route path="/catalog" element={<CatalogIndexPage />} />
                    <Route
                        path="/catalog/:namespace/:kind/:name"
                        element={<CatalogEntityPage />}
                    >
                        {entityPage}
                    </Route>
                    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
                    <Route path="/docs" element={<TechDocsIndexPage />} />
                    <Route
                        path="/docs/:namespace/:kind/:name/*"
                        element={<TechDocsReaderPage />}
                    >
                        <TechDocsAddons>
                            <ReportIssue />
                            <Mermaid config={{ theme: 'neutral' }} />
                        </TechDocsAddons>
                    </Route>
                    <Route path="/search" element={<SearchPage />}>
                        {searchPage}
                    </Route>
                    <Route path="/api-docs" element={<ApiExplorerPage />} />
                    <Route
                        path="/catalog-import"
                        element={
                            <RequirePermission permission={catalogEntityCreatePermission}>
                                <CatalogImportPage />
                            </RequirePermission>
                        }
                    />
                    <Route path="/settings" element={<UserSettingsPage />} />
                    <Route path="/version-drift" element={<VersionDriftPage />} />
                </FlatRoutes>
            </Root>
        </AppRouter>
    </>
)

export default app.createRoot(AppRoot);
