import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
    Settings as SidebarSettings,
    UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import {
    Sidebar,
    sidebarConfig,
    SidebarDivider,
    SidebarGroup,
    SidebarItem,
    SidebarPage,
    SidebarSpace,
    useSidebarOpenState,
    Link,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import { SidebarSearchModal } from '@backstage/plugin-search';

const useSidebarLogoStyles = makeStyles({
    root: {
        width: sidebarConfig.drawerWidthClosed,
        height: 3 * sidebarConfig.logoHeight,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        marginBottom: -14,
    },
    link: {
        width: sidebarConfig.drawerWidthClosed,
        marginLeft: 24,
    },
});

const SidebarLogo = () => {
    const classes = useSidebarLogoStyles();
    const { isOpen } = useSidebarOpenState();

    return (
        <div className={classes.root}>
            <Link to="/" underline="none" className={classes.link} aria-label="Home">
                {isOpen ? <LogoFull /> : <LogoIcon />}
            </Link>
        </div>
    );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
    return (
        <SidebarPage>
            <Sidebar>
                <SidebarLogo />
                <SidebarSearchModal />
                <SidebarDivider />
                <SidebarGroup label="Menu" icon={<MenuIcon />}>
                    <SidebarItem icon={HomeIcon} to="catalog" text="Home" />
                    <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
                    <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
                    <SidebarDivider />
                    <SidebarItem icon={MenuIcon} to="version-drift" text="Version Drift" />
                    <SidebarDivider />
                </SidebarGroup>
                <SidebarSpace />
                <SidebarDivider />
                <SidebarGroup
                    label="Settings"
                    icon={<UserSettingsSignInAvatar />}
                    to="/settings"
                >
                    <SidebarSettings />
                </SidebarGroup>
            </Sidebar>
            {children}
        </SidebarPage>
    )
};
