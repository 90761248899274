import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const versionDriftPlugin = createPlugin({
  id: 'version-drift',
  routes: {
    root: rootRouteRef,
  },
});

export const VersionDriftPage = versionDriftPlugin.provide(
  createRoutableExtension({
    name: 'VersionDriftPage',
    component: () =>
      import('./App').then(m => m.App),
    mountPoint: rootRouteRef,
  }),
);
